import { css } from 'styled-components';

import { atMinWidth } from '../../styles/atoms/breakpoints';

export const customInnerStyle = (
  removeBottomPadding,
  includeHeroPadding
) => css`
  && {
    padding-bottom: ${removeBottomPadding ? '0' : ''};
    padding-top: ${includeHeroPadding ? '115px' : ''};

    ${atMinWidth.md`
      padding-top: ${includeHeroPadding ? '139px' : ''};
    `}

    ${atMinWidth.lg`
      padding-top: ${includeHeroPadding ? '147px' : ''};
    `}
  }
`;
