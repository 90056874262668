import styled from 'styled-components';

import { font } from '../../../styles/atoms/typography';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;
  max-width: 770px;
  padding-top: 50px;
  margin: auto;

  .heading {
    ${font('display', 'xl', '700')}
    color: ${colors.gray[900]};

    ${atMinWidth.md`
      ${font('display', 'xxl', '700')}
    `}
  }

  .body-container {
    p{
      ${font('text', 'md', '400')}
      color: ${colors.gray[600]};

      ${atMinWidth.sm`
        ${font('text', 'lg', '400')}
      `}

      ${atMinWidth.md`
        ${font('text', 'xl', '400')}
      `}
    }
    
  }
`;
