import React from 'react';
import Section from '../../../molecules/Section/Section';
import { customInnerStyle } from '../../HeadingComponent/HeadingComponent.style';
import HeroBody from '../components/HeroBody';
import { Wrapper } from '../styles/PricingHero.styled';

const PricingHero = ({ component }) => (
  <Section id={component?.id} innerStyle={customInnerStyle(true, false)}>
    <Wrapper>
      {component?.heading && <h1 className="heading">{component?.heading}</h1>}
      {component?.body && <HeroBody component={component} />}
    </Wrapper>
  </Section>
);

export default PricingHero;
